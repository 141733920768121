export default class YiHexagram {

    constructor(private hexagram: number) { }

    private static fiveElements: { [key: number]: string; } = {
        0b000: "#9e5900",
        0b001: "#9e5900",
        0b010: "#005f98",
        0b011: "#00982d",
        0b100: "#00982d",
        0b101: "#b82200",
        0b110: "#ebbb00",
        0b111: "#ebbb00"
    }

    public getUpperFiveElementsColor(): string {
        let n = this.hexagram & 0b000111;
        return YiHexagram.fiveElements[n];
    }

    public getLowerFiveElementsColor(): string {
        let n = this.hexagram >> 3;
        return YiHexagram.fiveElements[n];
    }

    public split(): number[] {
        let number = this.hexagram;
        let line6 = number & 1;
        number >>= 1;
        let line5 = number & 1;
        number >>= 1;
        let line4 = number & 1;
        number >>= 1;
        let line3 = number & 1;
        number >>= 1;
        let line2 = number & 1;
        number >>= 1;
        let line1 = number & 1;
        number >>= 1;
        return [line1, line2, line3, line4, line5, line6];
    }
    private static names: { [key: number]: string; } = {
        0b000000: "坤",
        0b000001: "剥",
        0b000010: "比",
        0b000011: "观",
        0b000100: "豫",
        0b000101: "晋",
        0b000110: "萃",
        0b000111: "否",
        0b001000: "谦",
        0b001001: "艮",
        0b001010: "蹇",
        0b001011: "渐",
        0b001100: "小过",
        0b001101: "旅",
        0b001110: "咸",
        0b001111: "遁",
        0b010000: "师",
        0b010001: "蒙",
        0b010010: "习坎",
        0b010011: "涣",
        0b010100: "解",
        0b010101: "未济",
        0b010110: "困",
        0b010111: "讼",
        0b011000: "升",
        0b011001: "蛊",
        0b011010: "井",
        0b011011: "巽",
        0b011100: "恒",
        0b011101: "鼎",
        0b011110: "大过",
        0b011111: "姤",
        0b100000: "复",
        0b100001: "颐",
        0b100010: "屯",
        0b100011: "益",
        0b100100: "震",
        0b100101: "噬嗑",
        0b100110: "随",
        0b100111: "无妄",
        0b101000: "明夷",
        0b101001: "贲",
        0b101010: "既济",
        0b101011: "家人",
        0b101100: "丰",
        0b101101: "离",
        0b101110: "革",
        0b101111: "同人",
        0b110000: "临",
        0b110001: "损",
        0b110010: "节",
        0b110011: "中孚",
        0b110100: "归妹",
        0b110101: "睽",
        0b110110: "兑",
        0b110111: "履",
        0b111000: "泰",
        0b111001: "大畜",
        0b111010: "需",
        0b111011: "小畜",
        0b111100: "大壮",
        0b111101: "大有",
        0b111110: "夬",
        0b111111: "乾"
    }
    public getName(): string {
        return YiHexagram.names[this.hexagram];
    }
    private static texts: { [key: number]: string; } = {
        0b000000: "坤：元亨利牝马之贞君子有攸往先迷後得主利西南得朋东北丧朋安贞吉\r\n象曰：地势坤君子以厚德载物\r\n彖曰：至哉坤元万物资生乃顺承天坤厚载物德合无疆含弘光大品物咸亨牝马地类行地无疆柔顺利贞君子君子攸行先迷失道後顺得常西南得朋乃与类行东北丧朋乃终有庆安贞之吉应地无疆\r\n\r\n初六：履霜坚冰至\r\n象曰：履霜坚冰阴始凝也驯致其道至坚冰也\r\n\r\n六二：直方大不习无不利\r\n象曰：六二之动直以方也不习无不利地道光也\r\n\r\n六三：含章可贞或从王事无成有终\r\n象曰：含章可贞以时发也或従王事知光大也\r\n\r\n六四：括囊无咎无誉\r\n象曰：括囊无咎慎不害也\r\n\r\n六五：黄裳元吉\r\n象曰：黄裳元吉文在中也\r\n\r\n上六：龙战于野其血玄黄\r\n象曰：龙战于野共道穷也\r\n\r\n用六：利永贞\r\n象曰：用六永贞以大终也\r\n",
        0b000001: "剥：不利有攸往\r\n象曰：山附于地剥上以厚下安宅\r\n彖曰：剥剥也柔变刚也不利有攸往小人长也顺而止之观象也君子尚消息盈虚天行也\r\n\r\n初六：剥床以足蔑贞凶\r\n象曰：剥床以足以灭下也\r\n\r\n六二：剥床以辨蔑贞凶\r\n象曰：剥床以辨未有与也\r\n\r\n六三：剥之无咎\r\n象曰：剥之无咎失上下也\r\n\r\n六四：剥床以肤凶\r\n象曰：剥床以肤切近灾也\r\n\r\n六五：贯鱼以宫人宠无不利\r\n象曰：以宫人宠终无尤也\r\n\r\n上九：硕果不食君子得舆小人剥庐\r\n象曰：君子得舆民所载也小人剥庐终不可用也\r\n",
        0b000010: "比：吉原筮元永贞无咎不宁方来后夫凶\r\n象曰：地上有水比先王以建万国亲诸侯\r\n彖曰：比吉也比辅也下顺従也原筮元永贞无咎以刚中也不宁方来上下应也后夫凶其道穷也\r\n\r\n初六：有孚比之无咎有孚盈缶终来有它吉\r\n象曰：比之初六有它吉也\r\n\r\n六二：比之自内贞吉\r\n象曰：比之自内不自失也\r\n\r\n六三：比之匪人\r\n象曰：比之匪人不亦伤乎\r\n\r\n六四：外比之贞吉\r\n象曰：外比于贤以従上也\r\n\r\n九五：显比王用三驱失前禽邑人不诫吉\r\n象曰：显比之吉位正中也舍逆取顺失前禽也邑人不诫上使中也\r\n\r\n上六：比之无首凶\r\n象曰：比之无首无所终也\r\n",
        0b000011: "观：盥而不荐有孚颙若\r\n象曰：风行地上观先王以省方观民设教\r\n彖曰：大观在上顺而巽中正以观天下观盥而不荐有孚颙若下观而化也观天之神道而四时不忒圣人以神道设教而天下服矣\r\n\r\n初六：童观小人无咎君子吝\r\n象曰：初六童观小人道也\r\n\r\n六二：窥观利女贞\r\n象曰：窥观女贞亦可丑也\r\n\r\n六三：观我生进退\r\n象曰：观我生进退未失道也\r\n\r\n六四：观国之光利用宾于王\r\n象曰：观国之光尚宾也\r\n\r\n九五：观我生君子无咎\r\n象曰：观我生观民也\r\n\r\n上九：观其生君子无咎\r\n象曰：观其生志未平也\r\n",
        0b000100: "豫：利建侯行师\r\n象曰：雷出地奋豫先王以作乐崇德殷荐之上帝以配祖考\r\n彖曰：豫刚应而志行顺以动豫豫顺以动故天地如之而况建侯行师乎天地以顺动故日月不过而四时不忒圣人以顺动则刑罚清而民服豫之时义大矣哉\r\n\r\n初六：鸣豫凶\r\n象曰：初六鸣豫志穷凶也\r\n\r\n六二：介于石不终日贞吉\r\n象曰：不终日贞吉以中正也\r\n\r\n六三：盱豫悔迟有悔\r\n象曰：盱豫不悔位不当也\r\n\r\n九四：由豫大有得勿疑朋盍簪\r\n象曰：由豫大有得志大行也\r\n\r\n六五：贞疾恒不死\r\n象曰：六五贞疾乘刚也恒不死中未亡也\r\n\r\n上六：冥豫成有渝无咎\r\n象曰：冥豫在上何可长也\r\n",
        0b000101: "晋：康侯用锡马蕃庶昼日三接\r\n象曰：明出地上晋君子以自昭明德\r\n彖曰：晋进也明出地上顺而丽乎大明柔进而上行是以康侯用锡马蕃庶昼日三接也\r\n\r\n初六：晋如摧如贞吉罔孚裕无咎\r\n象曰：晋如摧如独行正也裕无咎未受命也\r\n\r\n六二：晋如愁如贞吉受兹介福于其王母\r\n象曰：受兹介福以中正也\r\n\r\n六三：众允悔亡\r\n象曰：众允之志上行也\r\n\r\n九四：晋如鼫鼠贞厉\r\n象曰：鼫鼠贞厉位不当也\r\n\r\n六五：悔亡失得勿恤往吉无不利\r\n象曰：失得勿恤往有庆也\r\n\r\n上九：晋其角维用伐邑厉吉无咎贞吝\r\n象曰：维用伐邑道未光也\r\n",
        0b000110: "萃：亨王假有庙利见大人亨利贞用大牲吉利有攸往\r\n象曰：泽上于地萃君子以除戎器戒不虞\r\n彖曰：萃聚也顺以说刚中而应故聚也王假有庙致孝享也利见大人亨聚以正也用大牲吉利有攸往顺天命也观其所聚而天地万物之情可见矣\r\n\r\n初六：有孚不终乃乱乃萃若号一握为笑勿恤往无咎\r\n象曰：乃乱乃萃其志乱也\r\n\r\n六二：引吉无咎孚乃利用禴\r\n象曰：引吉无咎中未变也\r\n\r\n六三：萃如嗟如无攸利往无咎小吝\r\n象曰：往无咎上巽也\r\n\r\n九四：大吉无咎\r\n象曰：大吉无咎位不当也\r\n\r\n九五：萃有位无咎匪孚元永贞悔亡\r\n象曰：萃有位志未光也\r\n\r\n上六：赍咨涕洟无咎\r\n象曰：赍咨涕洟未安上也\r\n",
        0b000111: "否：否之匪人不利君子贞大往小来\r\n象曰：天地不交否君子以俭德辟难不可荣以禄\r\n彖曰：否之匪人不利君子贞大往小来则是天地不交而万物不通也上下不交而天下无邦也内阴而外阳内柔而外刚内小人而外君子小人道长君子道消也\r\n\r\n初六：拔茅茹以其汇贞吉亨\r\n象曰：拔茅贞吉志在君也\r\n\r\n六二：包承小人吉大人否亨\r\n象曰：大人否亨不乱群也\r\n\r\n六三：包羞\r\n象曰：包羞位不当也\r\n\r\n九四：有命无咎畴离祉\r\n象曰：有命无咎志行也\r\n\r\n九五：休否大人吉其亡其亡系于苞桑\r\n象曰：大人之吉位正当也\r\n\r\n上九：倾否先否后喜\r\n象曰：否终则倾何可长也\r\n",
        0b001000: "谦：亨君子有终\r\n象曰：地中有山谦君子以裒多益寡称物平施\r\n彖曰：谦亨天道下济而光明地道卑而上行天道亏盈而益谦地道变盈而流谦鬼神害盈而福谦人道恶盈而好谦谦尊而光卑而不可逾君子之终也\r\n\r\n初六：谦谦君子用涉大川吉\r\n象曰：谦谦君子卑以自牧也\r\n\r\n六二：鸣谦贞吉\r\n象曰：鸣谦贞吉中心得也\r\n\r\n九三：劳谦君子有终吉\r\n象曰：劳谦君子万民服也\r\n\r\n六四：无不利捴谦\r\n象曰：无不利捴谦不违则也\r\n\r\n六五：不富以其邻利用侵伐无不利\r\n象曰：利用侵伐征不服也\r\n\r\n上六：鸣谦利用行师征邑国\r\n象曰：鸣谦志未得也可用行师征邑国也\r\n",
        0b001001: "艮：艮其背不获其身行其庭不见其人无咎\r\n象曰：兼山艮君子以思不出其位\r\n彖曰：艮止也时止则止时行则行动静不失其时其道光明艮其止止其所也上下敌应不相与也是以不获其身行其庭不见其人无咎也\r\n\r\n初六：艮其趾无咎利永贞\r\n象曰：艮其趾未失正也\r\n\r\n六二：艮其腓不拯其随其心不快\r\n象曰：不拯其随未退听也\r\n\r\n九三：艮其限列其夤厉熏心\r\n象曰：艮其限危熏心也\r\n\r\n六四：艮其身无咎\r\n象曰：艮其身止诸躬也\r\n\r\n六五：艮其辅言有序悔亡\r\n象曰：艮其辅以中正也\r\n\r\n上九：敦艮吉\r\n象曰：敦艮之吉以厚终也\r\n",
        0b001010: "蹇：利西南不利东北利见大人贞吉\r\n象曰：山上有水蹇君子以反身修德\r\n彖曰：蹇难也险在前也见险而能止知矣哉蹇利西南往得中也不利东北其道穷也利见大人往有功也当位贞吉以正邦也蹇之时用大矣哉\r\n\r\n初六：往蹇来誉\r\n象曰：往蹇来誉宜待也\r\n\r\n六二：王臣蹇蹇匪躬之故\r\n象曰：王臣蹇蹇终无尤也\r\n\r\n九三：往蹇来反\r\n象曰：往蹇来反内喜之也\r\n\r\n六四：往蹇来连\r\n象曰：往蹇来连当位实也\r\n\r\n九五：大蹇朋来\r\n象曰：大蹇朋来以中节也\r\n\r\n上六：往蹇来硕吉利见大人\r\n象曰：往蹇来硕志在内也利见大人以従贵也\r\n",
        0b001011: "渐：女归吉利贞\r\n象曰：山上有木渐君子以居贤德善俗\r\n彖曰：渐之进也女归吉也进得位往有功也进以正可以正邦也其位刚得中也止而巽动不穷也\r\n\r\n初六：鸿渐于干小子厉有言无咎\r\n象曰：小子之厉义无咎也\r\n\r\n六二：鸿渐于磐饮食衎衎吉\r\n象曰：饮食衎衎不素饱也\r\n\r\n九三：鸿渐于陆夫征不复妇孕不育凶利御寇\r\n象曰：夫征不复离群丑也妇孕不育失其道也利用御寇顺相保也\r\n\r\n六四：鸿渐于木或得其桷无咎\r\n象曰：或得其桷顺以巽也\r\n\r\n九五：鸿渐于陵妇三岁不孕终莫之胜吉\r\n象曰：终莫之胜吉得所愿也\r\n\r\n上九：鸿渐于陆其羽可用为仪吉\r\n象曰：其羽可用为仪吉不可乱也\r\n",
        0b001100: "小过：亨利贞可小事不可大事飞鸟遗之音不宜上宜下大吉\r\n象曰：山上有雷小过君子以行过乎恭丧过乎哀用过乎俭\r\n彖曰：小过小者过而亨也过以利贞与时行也柔得中是以小事吉也刚失位而不中是以不可大事也有飞鸟之象焉飞鸟遗之音不宜上宜下大吉上逆而下顺也\r\n\r\n初六：飞鸟以凶\r\n象曰：飞鸟以凶不可如何也\r\n\r\n六二：过其祖遇其妣不及其君遇其臣无咎\r\n象曰：不及其君臣不可过也\r\n\r\n九三：弗过防之従或戕之凶\r\n象曰：従或戕之凶如何也\r\n\r\n九四：无咎弗过遇之往厉必戒勿用永贞\r\n象曰：弗过遇之位不当也往厉必戒终不可长也\r\n\r\n六五：密云不雨自我西郊公弋取彼在穴\r\n象曰：密云不雨已上也\r\n\r\n上六：弗遇过之飞鸟离之凶是谓灾眚\r\n象曰：弗遇过之已亢也\r\n",
        0b001101: "旅：小亨旅贞吉\r\n象曰：山上有火旅君子以明慎用刑而不留狱\r\n彖曰：旅小亨柔得中乎外而顺乎刚止而丽乎明是以小亨旅贞吉也旅之时义大矣哉\r\n\r\n初六：旅琐琐斯其所取灾\r\n象曰：旅琐琐志穷灾也\r\n\r\n六二：旅即次怀其资得童仆贞\r\n象曰：得童仆贞终无尤也\r\n\r\n九三：旅焚其次丧其童仆贞厉\r\n象曰：旅焚其次亦以伤矣以旅与下其义丧也\r\n\r\n九四：旅于处得其资斧我心不快\r\n象曰：旅于处未得位也得其资斧心未快也\r\n\r\n六五：射雉一矢亡终以誉命\r\n象曰：终以誉命上逮也\r\n\r\n上九：鸟焚其巢旅人先笑后号咷丧牛于易凶\r\n象曰：以旅在上其义焚也丧牛于易终莫之闻也\r\n",
        0b001110: "咸：亨利贞取女吉\r\n象曰：山上有泽咸君子以虚受人\r\n彖曰：咸感也柔上而刚下二气感应以相与止而说男下女是以亨利贞取女吉也天地感而万物化生圣人感人心而天下和平观其所感而天地万物之情可见矣\r\n\r\n初六：咸其拇\r\n象曰：咸其拇志在外也\r\n\r\n六二：咸其腓凶居吉\r\n象曰：凶居吉顺不害也\r\n\r\n九三：咸其股执其随往吝\r\n象曰：咸其股亦不处也志在随人所执下也\r\n\r\n九四：贞吉悔亡憧憧往来朋従尔思\r\n象曰：贞吉悔亡未感害也憧憧往来未光大也\r\n\r\n九五：咸其脢无悔\r\n象曰：咸其脢志末也\r\n\r\n上六：咸其辅颊舌\r\n象曰：咸其辅颊舌滕口说也\r\n",
        0b001111: "遁：亨小利贞\r\n象曰：天下有山遁君子以远小人不恶而严\r\n彖曰：遁亨遁而亨也刚当位而应与时行也小利贞浸而长也遁之时义大矣哉\r\n\r\n初六：遁尾厉勿用有攸往\r\n象曰：遁尾之厉不往何灾也\r\n\r\n六二：执之用黄牛之革莫之胜说\r\n象曰：执用黄牛固志也\r\n\r\n九三：系遁有疾厉畜臣妾吉\r\n象曰：系遁之厉有疾惫也畜臣妾吉不可大事也\r\n\r\n九四：好遁君子吉小人否\r\n象曰：君子好遁小人否也\r\n\r\n九五：嘉遁贞吉\r\n象曰：嘉遁贞吉以正志也\r\n\r\n上九：肥遁无不利\r\n象曰：肥遁无不利无所疑也\r\n",
        0b010000: "师：贞丈人吉无咎\r\n象曰：地中有水师君子以容民畜众\r\n彖曰：师众也贞正也能以众正可以王矣刚中而应行险而顺以此毒天下而民従之吉又何咎矣\r\n\r\n初六：师出以律否臧凶\r\n象曰：师出以律失律凶也\r\n\r\n九二：在师中吉无咎王三锡命\r\n象曰：在师中吉承天宠也王三锡命怀万邦也\r\n\r\n六三：师或舆尸凶\r\n象曰：师或舆尸大无功也\r\n\r\n六四：师左次无咎\r\n象曰：左次无咎未失常也\r\n\r\n六五：田有禽利执言无咎长子帅师弟子舆尸贞凶\r\n象曰：长子帅师以中行也弟子舆尸使不当也\r\n\r\n上六：大君有命开国承家小人勿用\r\n象曰：大君有命以正功也小人勿用必乱邦也\r\n",
        0b010001: "蒙：亨匪我求童蒙童蒙求我初筮告再三渎渎则不告利贞\r\n象曰：山下出泉蒙君子以果行育德\r\n彖曰：蒙山下有险险而止蒙蒙亨以亨行时中也匪我求童蒙童蒙求我志应也初筮告以刚中也再三渎渎则不告渎蒙也蒙以养正圣功也\r\n\r\n初六：发蒙利用刑人用说桎梏以往吝\r\n象曰：利用刑人以正法也\r\n\r\n九二：包蒙吉纳妇吉子克家\r\n象曰：子克家刚柔节也\r\n\r\n六三：勿用取女见金夫不有躬无攸利\r\n象曰：勿用取女行不顺也\r\n\r\n六四：困蒙吝\r\n象曰：困蒙之吝独远实也\r\n\r\n六五：童蒙吉\r\n象曰：童蒙之吉顺以巽也\r\n\r\n上九：击蒙不利为寇利御寇\r\n象曰：利用御寇上下顺也\r\n",
        0b010010: "习坎：有孚维心亨行有尚\r\n象曰：水洊至习坎君子以常德行习教事\r\n彖曰：习坎重险也水流而不盈行险而不失其信维心亨乃以刚中也行有尚往有功也天险不可升也地险山川丘陵也王公设险以守其国险之时用大矣哉\r\n\r\n初六：习坎入于坎窞凶\r\n象曰：习坎入坎失道凶也\r\n\r\n九二：坎有险求小得\r\n象曰：求小得未出中也\r\n\r\n六三：来之坎坎险且枕入于坎窞勿用\r\n象曰：来之坎坎终无功也\r\n\r\n六四：樽酒簋贰用缶纳约自牖终无咎\r\n象曰：樽酒簋贰刚柔际也\r\n\r\n九五：坎不盈祗既平无咎\r\n象曰：坎不盈中未大也\r\n\r\n上六：系用徽纆窴于丛棘三岁不得凶\r\n象曰：上六失道凶三岁也\r\n",
        0b010011: "涣：亨王假有庙利涉大川利贞\r\n象曰：风行水上涣先王以享于帝立庙\r\n彖曰：涣亨刚来而不穷柔得位乎外而上同王假有庙王乃在中也利涉大川乘木有功也\r\n\r\n初六：用拯马壮吉\r\n象曰：初六之吉顺也\r\n\r\n九二：涣奔其机悔亡\r\n象曰：涣奔其机得愿也\r\n\r\n六三：涣其躬无悔\r\n象曰：涣其躬志在外也\r\n\r\n六四：涣其群元吉涣有丘匪夷所思\r\n象曰：涣其群元吉光大也\r\n\r\n九五：涣汗其大号涣王居无咎\r\n象曰：王居无咎正位也\r\n\r\n上九：涣其血去逖出无咎\r\n象曰：涣其血远害也\r\n",
        0b010100: "解：利西南无所往其来复吉有攸往夙吉\r\n象曰：雷雨作解君子以赦过宥罪\r\n彖曰：解险以动动而免乎险解解利西南往得众也其来复吉乃得中也有攸往夙吉往有功也天地解而雷雨作雷雨作而百果草木皆甲坼解之时大矣哉\r\n\r\n初六：无咎\r\n象曰：刚柔之际义无咎也\r\n\r\n九二：田获三狐得黄矢贞吉\r\n象曰：九二贞吉得中道也\r\n\r\n六三：负且乘致寇至贞吝\r\n象曰：负且乘亦可丑也自我致戎又谁咎也\r\n\r\n九四：解而拇朋至斯孚\r\n象曰：解而拇未当位也\r\n\r\n六五：君子维有解吉有孚于小人\r\n象曰：君子有解小人退也\r\n\r\n上六：公用射隼于高墉之上获之无不利\r\n象曰：公用射隼以解悖也\r\n",
        0b010101: "未济：亨小狐汔济濡其尾无攸利\r\n象曰：火在水上未济君子以慎辨物居方\r\n彖曰：未济亨柔得中也小狐汔济未出中也濡其尾无攸利不续终也虽不当位刚柔应也\r\n\r\n初六：濡其尾吝\r\n象曰：濡其尾亦不知极也\r\n\r\n九二：曳其轮贞吉\r\n象曰：九二贞吉中以行正也\r\n\r\n六三：未济征凶利涉大川\r\n象曰：未济征凶位不当也\r\n\r\n九四：贞吉悔亡震用伐鬼方三年有赏于大国\r\n象曰：贞吉悔亡志行也\r\n\r\n六五：贞吉无悔君子之光有孚吉\r\n象曰：君子之光其辉吉也\r\n\r\n上九：有孚于饮酒无咎濡其首有孚失是\r\n象曰：饮酒濡首亦不知节也\r\n",
        0b010110: "困：亨贞大人吉无咎有言不信\r\n象曰：泽无水困君子以致命遂志\r\n彖曰：困刚掩也险以说因而不失其所亨其唯君子乎贞大人吉以刚中也有言不信尚口乃穷也\r\n\r\n初六：臀困于株木入于幽谷三岁不觌\r\n象曰：入于幽谷幽不明也\r\n\r\n九二：困于酒食朱绂方来利用享祀征凶无咎\r\n象曰：困于酒食中有庆也\r\n\r\n六三：困于石据于蒺藜入于其宫不见其妻凶\r\n象曰：据于蒺藜乘刚也入于其宫不见其妻不祥也\r\n\r\n九四：来徐徐困于金车吝有终\r\n象曰：来徐徐志在下也虽不当位有与也\r\n\r\n九五：劓刖困于赤绂乃徐有说利用祭祀\r\n象曰：劓刖志未得也乃徐有说以中直也利用祭祀受福也\r\n\r\n上六：困于葛藟于臲<臬兀>曰动悔有悔征吉\r\n象曰：困于葛藟未当也动悔有悔吉行也\r\n",
        0b010111: "讼：有孚窒惕中吉终凶利见大人不利涉大川\r\n象曰：天与水违行讼君子以作事谋始\r\n彖曰：讼上刚下险险而健讼讼有孚窒惕中吉刚来而得中也终凶讼不可成也利见大人尚中正也不利涉大川入于渊也\r\n\r\n初六：不永所事小有言终吉\r\n象曰：不永所事讼不可长也虽小有言其辩明也\r\n\r\n九二：不克讼归而逋其邑人三百户无眚\r\n象曰：不克讼归逋窜也自下讼上患至掇也\r\n\r\n六三：食旧德贞厉终吉或従王事无成\r\n象曰：食旧德従上吉也\r\n\r\n九四：不克讼复既命渝安贞吉\r\n象曰：复即命渝安贞不失也\r\n\r\n九五：讼元吉\r\n象曰：讼元吉以中正也\r\n\r\n上九：或锡之鞶带终朝三褫之\r\n象曰：以讼受服亦不足敬也\r\n",
        0b011000: "升：元亨用见大人勿恤南征吉\r\n象曰：地中生木升君子以顺德积小以高大\r\n彖曰：柔以时升巽而顺刚中而应是以大亨用见大人勿恤有庆也南征吉志行也\r\n\r\n初六：允升大吉\r\n象曰：允升大吉上合志也\r\n\r\n九二：孚乃利用禴无咎\r\n象曰：九二之孚有喜也\r\n\r\n九三：升虚邑\r\n象曰：升虚邑无所疑也\r\n\r\n六四：王用亨于岐山吉无咎\r\n象曰：王用亨于岐山顺事也\r\n\r\n六五：贞吉升阶\r\n象曰：贞吉升阶大得志也\r\n\r\n上六：冥升利于不息之贞\r\n象曰：冥升在上消不富也\r\n",
        0b011001: "蛊：元亨利涉大川先甲三日后甲三日\r\n象曰：山下有风蛊君子以振民育德\r\n彖曰：蛊刚上而柔下巽而止蛊蛊元亨而天下治也利涉大川往有事也先甲三日后甲三日终则有始天行也\r\n\r\n初六：干父之蛊有子考无咎厉终吉\r\n象曰：干父之蛊意承考也\r\n\r\n九二：干母之蛊不可贞\r\n象曰：干母之蛊得中道也\r\n\r\n九三：干父之蛊小有悔无大咎\r\n象曰：干父之蛊终无咎也\r\n\r\n六四：裕父之蛊往见吝\r\n象曰：裕父之蛊往未得也\r\n\r\n六五：干父之蛊用誉\r\n象曰：干父用誉承以德也\r\n\r\n上九：不事王侯高尚其事\r\n象曰：不事王侯志可则也\r\n",
        0b011010: "井：改邑不改井无丧无得往来井井汔至亦未繘井羸其瓶凶\r\n象曰：木上有水井君子以劳民劝相\r\n彖曰：巽乎水而上水井井养而不穷也改邑不改井乃以刚中也汔至亦未繘井未有功也羸其瓶是以凶也\r\n\r\n初六：井泥不食旧井无禽\r\n象曰：井泥不食下也占井无禽时舍也\r\n\r\n九二：井谷射鲋瓮敝漏\r\n象曰：井谷射鲋无与也\r\n\r\n九三：井渫不食为我心恻可用汲王明并受其福\r\n象曰：井渫不食行恻也求王明受福也\r\n\r\n六四：井甃无咎\r\n象曰：井甃无咎修井也\r\n\r\n九五：井洌寒泉食\r\n象曰：寒泉之食中正也\r\n\r\n上六：井收勿幕有孚元吉\r\n象曰：元吉在上大成也\r\n",
        0b011011: "巽：小亨利有攸往利见大人\r\n象曰：随风巽君子以申命行事\r\n彖曰：重巽以申命刚巽乎中正而志行柔皆顺乎刚是以小亨利有攸往利见大人\r\n\r\n初六：进退利武人之贞\r\n象曰：进退志疑也利武人之贞志治也\r\n\r\n九二：巽在床下用史巫纷若吉无咎\r\n象曰：纷若之吉得中也\r\n\r\n九三：频巽吝\r\n象曰：频巽之吝志穷也\r\n\r\n六四：悔亡田获三品\r\n象曰：田获三品有功也\r\n\r\n九五：贞吉悔亡无不利无初有终先庚三日后庚三日吉\r\n象曰：九五之吉位正中也\r\n\r\n上九：巽在床下丧其资斧贞凶\r\n象曰：巽在床下上穷也丧其资斧正乎凶也\r\n",
        0b011100: "恒：亨无咎利贞利有攸往\r\n象曰：雷风恒君子以立不易方\r\n彖曰：恒久也刚上而柔下雷风相与巽而动刚柔皆应恒恒亨无咎利贞久于其道也天地之道恒久而不已也利有攸往终则有始也日月得天而能久照四时变化而能久成圣人久于其道而天下化成观其所恒而天地万物之情可见矣\r\n\r\n初六：浚恒贞凶无攸利\r\n象曰：浚恒之凶始求深也\r\n\r\n九二：悔亡\r\n象曰：九二悔亡能久中也\r\n\r\n九三：不恒其德或承之羞贞吝\r\n象曰：不恒其德无所容也\r\n\r\n九四：田无禽\r\n象曰：久非其位安得禽也\r\n\r\n六五：恒其德贞妇人吉夫子凶\r\n象曰：妇人贞吉従一而终也夫子制义従妇凶也\r\n\r\n上六：振恒凶\r\n象曰：振恒在上大无功也\r\n",
        0b011101: "鼎：元吉亨\r\n象曰：木上有火鼎君子以正位凝命\r\n彖曰：鼎象也以木巽火亨饪也圣人亨以享上帝而大亨以养圣贤巽而耳目聪明柔进而上行得中而应乎刚是以元亨\r\n\r\n初六：鼎颠趾利出否得妾以其子无咎\r\n象曰：鼎颠趾未悖也利出否以従贵也\r\n\r\n九二：鼎有实我仇有疾不我能即吉\r\n象曰：鼎有实慎所之也我仇有疾终无尤也\r\n\r\n九三：鼎耳革其行塞雉膏不食方雨亏悔终吉\r\n象曰：鼎耳革失其义也\r\n\r\n九四：鼎折足覆公餗其形渥凶\r\n象曰：覆公餗信如何也\r\n\r\n六五：鼎黄耳金铉利贞\r\n象曰：鼎黄耳中以为实也\r\n\r\n上九：鼎玉铉大吉无不利\r\n象曰：玉铉在上刚柔节也\r\n",
        0b011110: "大过：栋挠利有攸往亨\r\n象曰：泽灭木大过君子以独立不惧遯世无闷\r\n彖曰：大过大者过也栋挠本末弱也刚过而中巽而说行利有攸往乃亨大过之时大矣哉\r\n\r\n初六：藉用白茅无咎\r\n象曰：藉用白茅柔在下也\r\n\r\n九二：枯杨生稊老夫得其女妻无不利\r\n象曰：老夫女妻过以相与也\r\n\r\n九三：栋桡凶\r\n象曰：栋桡之凶不可以有辅也\r\n\r\n九四：栋隆吉有它吝\r\n象曰：栋隆之吉不桡乎下也\r\n\r\n九五：枯杨生华老妇得其士夫无咎无誉\r\n象曰：枯杨生华何可久也无妇士夫亦可丑也\r\n\r\n上六：过涉灭顶凶无咎\r\n象曰：过涉之凶不可咎也\r\n",
        0b011111: "姤：女壮勿用取女\r\n象曰：天下有风姤后以施命诰四方\r\n彖曰：姤遇也柔遇刚也勿用取女不可与长也天地相遇品物咸章也刚遇中正天下大行也姤之时义大矣哉\r\n\r\n初六：系于金柅贞吉有攸往见凶羸豕孚蹢躅\r\n象曰：系于金柅柔道牵也\r\n\r\n九二：包有鱼无咎不利宾\r\n象曰：包有鱼义不及宾也\r\n\r\n九三：臀无肤其行次且厉无大咎\r\n象曰：其行次且行未牵也\r\n\r\n九四：包无鱼起凶\r\n象曰：无鱼之凶远民也\r\n\r\n九五：以杞包瓜含章有陨自天\r\n象曰：九五含章中正也有陨自天志不舍命也\r\n\r\n上九：姤其角吝无咎\r\n象曰：姤其角上穷吝也\r\n",
        0b100000: "复：亨出入无疾朋来无咎反复其道七日来复利有攸往\r\n象曰：雷在地中复先王以至日闭关商旅不行后不省方\r\n彖曰：复亨刚反动而以顺行是以出入无疾朋来无咎反复其道七日来复天行也利有攸往刚长也复其见天地之心乎\r\n\r\n初九：不远复无祗悔元吉\r\n象曰：不远之复以修身也\r\n\r\n六二：休复吉\r\n象曰：休复之吉以下仁也\r\n\r\n六三：频复厉无咎\r\n象曰：频复之厉义无咎也\r\n\r\n六四：中行独复\r\n象曰：中行独复以従道也\r\n\r\n六五：敦复无悔\r\n象曰：敦复无悔中以自考也\r\n\r\n上六：迷复凶有灾眚用行师终有大败以其国君凶至于十年不克征\r\n象曰：迷复之凶反君道也\r\n",
        0b100001: "颐：贞吉观颐自求口实\r\n象曰：山下有雷颐君子以慎言语节饮食\r\n彖曰：颐贞吉养正则吉也观颐观其所养也自求口实观其自养也天地养万物圣人养贤以及万民颐之时大矣哉\r\n\r\n初九：舍尔灵龟观我朵颐凶\r\n象曰：观我朵颐亦不足贵也\r\n\r\n六二：颠颐拂经于丘颐征凶\r\n象曰：六二征凶行失类也\r\n\r\n六三：拂颐贞凶十年勿用无攸利\r\n象曰：十年勿用道大悖也\r\n\r\n六四：颠颐吉虎视眈眈其欲逐逐无咎\r\n象曰：颠颐之吉上施光也\r\n\r\n六五：拂经居贞吉不可涉大川\r\n象曰：居贞之吉顺以従上也\r\n\r\n上九：由颐厉吉利涉大川\r\n象曰：由颐厉吉大有庆也\r\n",
        0b100010: "屯：元亨利贞勿用有攸往利建侯\r\n象曰：云雷屯君子以经纶\r\n彖曰：屯刚柔始交而难生动乎险中大亨贞雷雨之动满盈天造草昧宜寻建侯而不宁\r\n\r\n初九：磐桓利居贞利建侯\r\n象曰：虽磐桓志行正也以贵下贱大得民也\r\n\r\n六二：屯如邅如乘马班如匪寇婚媾女子贞不字十年乃字\r\n象曰：六二之难乘刚也十年乃字反常也\r\n\r\n六三：即鹿无虞惟入于林中君子几不如舍往吝\r\n象曰：即鹿无虞以従禽也君子舍之往吝穷也\r\n\r\n六四：乘马班如求婚媾往吉无不利\r\n象曰：求而往明也\r\n\r\n九五：屯其膏小贞吉大贞凶\r\n象曰：屯其膏施未光也\r\n\r\n上六：乘马班如泣血涟如\r\n象曰：泣血涟如何可长也\r\n",
        0b100011: "益：利有攸往利涉大川\r\n象曰：风雷益君子以见善则迁有过则改\r\n彖曰：益损上益下民说无疆自上下下其道大光利有攸往中正有庆利涉大川木道乃行益动而巽日进无疆天施地生其益无方凡益之道与时偕行\r\n\r\n初九：利用为大作元吉无咎\r\n象曰：元吉无咎下不厚事也\r\n\r\n六二：或益之十朋之龟弗克违永贞吉王用享于帝吉\r\n象曰：或益之自外来也\r\n\r\n六三：益之用凶事无咎有孚中行告公用圭\r\n象曰：益用凶事固有之也\r\n\r\n六四：中行告公従利用为依迁国\r\n象曰：告公従以益志也\r\n\r\n九五：有孚惠心勿问元吉有孚惠我德\r\n象曰：有孚惠心勿问之矣惠我德大得志也\r\n\r\n上九：莫益之或击之立心勿恒凶\r\n象曰：莫益之偏辞也或击之自外来也\r\n",
        0b100100: "震：亨震来虩虩笑言哑哑震惊百里不丧匕鬯\r\n象曰：洊雷震君子以恐惧修省\r\n彖曰：震亨震来虩虩恐致福也笑言哑哑后有则也震惊百里惊远而惧迩也不丧匕鬯出可以守宗庙社稷以为祭主也\r\n\r\n初九：震来虩虩后笑言哑哑吉\r\n象曰：震来虩虩恐致福也笑言哑哑后有则也\r\n\r\n六二：震来厉亿丧贝跻于九陵勿逐七日得\r\n象曰：震来厉乘刚也\r\n\r\n六三：震苏苏震行无眚\r\n象曰：震苏苏位不当也\r\n\r\n九四：震遂泥\r\n象曰：震遂泥未光也\r\n\r\n六五：震往来厉意无丧有事\r\n象曰：震往来厉危行也其事在中大无丧也\r\n\r\n上六：震索索视矍矍征凶震不于其躬于其邻无咎婚媾有言\r\n象曰：震索索中未得也虽凶无咎畏邻戒也\r\n",
        0b100101: "噬嗑：亨利用狱\r\n象曰：雷电噬嗑先王以明罚敕法\r\n彖曰：颐中有物曰噬嗑噬嗑而亨刚柔分动而明雷电合而章柔得中而上行虽不当位利用狱也\r\n\r\n初九：屦校灭趾无咎\r\n象曰：屦校灭趾不行也\r\n\r\n六二：噬肤灭鼻无咎\r\n象曰：噬肤灭鼻乘刚也\r\n\r\n六三：噬腊肉遇毒小吝无咎\r\n象曰：遇毒位不当也\r\n\r\n九四：噬干胏得金矢利艰贞吉\r\n象曰：利艰贞吉未光也\r\n\r\n六五：噬干肉得黄金贞厉无咎\r\n象曰：贞厉无咎得当也\r\n\r\n上九：何校灭耳凶\r\n象曰：何校灭耳聪不明也\r\n",
        0b100110: "随：元亨利贞无咎\r\n象曰：泽中有雷随君子以向晦入宴息\r\n彖曰：随刚来而下柔动而说随大亨贞无咎而天下随时随时之义大矣哉\r\n\r\n初九：官有渝贞吉出门交有功\r\n象曰：官有渝従正吉也出门交有功不失也\r\n\r\n六二：系小子失丈夫\r\n象曰：系小子弗兼与也\r\n\r\n六三：系丈夫失小子随有求得利居贞\r\n象曰：系丈夫志舍下也\r\n\r\n九四：随有获贞凶有孚在道以明何咎\r\n象曰：随有获其义凶也有孚在道明功也\r\n\r\n九五：孚于嘉吉\r\n象曰：孚于嘉吉位正中也\r\n\r\n上六：拘系之乃従维之王用亨于西山\r\n象曰：拘系之上穷也\r\n",
        0b100111: "无妄：元亨利贞其匪正有眚不利有攸往\r\n象曰：天下雷行物与无妄先王以茂对时育万物\r\n彖曰：无妄刚自外来而为主于内动而健刚中而应大亨以正天之命也其匪正有眚不利有攸往无妄之往何之矣天命不祐行矣哉\r\n\r\n初九：无妄往吉\r\n象曰：无妄之往得志也\r\n\r\n六二：不耕获不菑畬则利用攸往\r\n象曰：不耕获未富也\r\n\r\n六三：无妄之灾或系之牛行人之得邑人之灾\r\n象曰：行人得牛邑人灾也\r\n\r\n九四：可贞无咎\r\n象曰：可贞无咎固有之也\r\n\r\n九五：无妄之疾勿药有喜\r\n象曰：无妄之药不可试也\r\n\r\n上九：无妄行有眚无攸利\r\n象曰：无妄之行穷之灾也\r\n",
        0b101000: "明夷：利艰贞\r\n象曰：明入地中明夷君子以莅众用晦而明\r\n彖曰：明入地中明夷内文明而外柔顺以蒙大难文王以之利艰贞晦其明也内难而能正其志箕子以之\r\n\r\n初九：明夷于飞垂其翼君子于行三日不食有攸往主人有言\r\n象曰：君子于行义不食也\r\n\r\n六二：明夷夷于左股用拯马壮吉\r\n象曰：六二之吉顺以则也\r\n\r\n九三：明夷于南狩得其大首不可疾贞\r\n象曰：南狩之志乃得大也\r\n\r\n六四：入于左腹获明夷之心于出门庭\r\n象曰：入于左腹获心意也\r\n\r\n六五：箕子之明夷利贞\r\n象曰：箕子之贞明不可息也\r\n\r\n上六：不明晦初登于天后入于地\r\n象曰：初登于天照四国也后入天地失则也\r\n",
        0b101001: "贲：亨小利有攸往\r\n象曰：山下有火贲君子以明庶政无敢折狱\r\n彖曰：贲亨柔来而文刚故亨分刚上而文柔故小利有攸往刚柔交错天文也文明以止人文也观乎天文以察时变观乎人文以化成天下\r\n\r\n初九：贲其趾舍车而徒\r\n象曰：舍车而徒义弗乘也\r\n\r\n六二：贲其须\r\n象曰：贲其须与上兴也\r\n\r\n九三：贲如濡如永贞吉\r\n象曰：永贞之吉终莫之陵也\r\n\r\n六四：贲如皤如白马翰如匪寇婚媾\r\n象曰：六四当位疑也匪寇婚媾终无尤也\r\n\r\n六五：贲于丘园束帛戋戋吝终吉\r\n象曰：六五之吉有喜也\r\n\r\n上九：白贲无咎\r\n象曰：白贲无咎上得志也\r\n",
        0b101010: "既济：亨小利贞初吉终乱\r\n象曰：水在火上既济君子以思患而豫防之\r\n彖曰：既济亨小者亨也利贞刚柔正而位当也初吉柔得中也终止则乱其道穷也\r\n\r\n初九：曳其轮濡其尾无咎\r\n象曰：曳其轮义无咎也\r\n\r\n六二：妇丧其茀勿逐七日得\r\n象曰：七日得以中道也\r\n\r\n九三：高宗伐鬼方三年克之小人勿用\r\n象曰：三年克之惫也\r\n\r\n六四：繻有衣袽终日戒\r\n象曰：终日戒有所疑也\r\n\r\n九五：东邻杀牛不如西邻之禴祭实受其福\r\n象曰：东邻杀牛不如西邻之时也实受其福吉大来也\r\n\r\n上六：濡其首厉\r\n象曰：濡其首厉何可久也\r\n",
        0b101011: "家人：利女贞\r\n象曰：风自火出家人君子以言有物而行有恒\r\n彖曰：家人女正位乎内男正位乎外男女正天地之大义也家人有严君焉父母之谓也父父子子兄兄弟弟夫夫妇妇而家道正正家而天下定矣\r\n\r\n初九：闲有家悔亡\r\n象曰：闲有家志未变也\r\n\r\n六二：无攸遂在中馈贞吉\r\n象曰：六二之吉顺以巽也\r\n\r\n九三：家人嗃々悔厉吉妇子嘻嘻终吝\r\n象曰：家人嗃々未失也妇子嘻嘻失家节也\r\n\r\n六四：富家大吉\r\n象曰：富家大吉顺在位也\r\n\r\n九五：王假有家勿恤吉\r\n象曰：王假有家交相爱也\r\n\r\n上九：有孚威如终吉\r\n象曰：威如之吉反身之谓也\r\n",
        0b101100: "丰：亨王假之勿忧宜日中\r\n象曰：雷电皆至丰君子以折狱致刑\r\n彖曰：丰大也明以动故丰王假之尚大也勿忧宜日中宜照天下也日中则昃月盈则食天地盈虚与时消息而况于人乎况于鬼神乎\r\n\r\n初九：遇其配主虽旬无咎往有尚\r\n象曰：虽旬无咎过旬灾也\r\n\r\n六二：丰其蔀日中见斗往得疑疾有孚发若吉\r\n象曰：有孚发若信以发志也\r\n\r\n九三：丰其沛日中见沫折其右肱无咎\r\n象曰：丰其沛不可大事也折其右肱终不可用也\r\n\r\n九四：丰其蔀日中见斗遇其夷主吉\r\n象曰：丰其蔀位不当也日中见斗幽不明也遇其夷主吉行也\r\n\r\n六五：来章有庆誉吉\r\n象曰：六五之吉有庆也\r\n\r\n上六：丰其屋蔀其家窥其户阒其无人三岁不觌凶\r\n象曰：丰其屋天际翔也窥其户阒其无人自藏也\r\n",
        0b101101: "离：利贞亨畜牝牛吉\r\n象曰：明两作离大人以继明照于四方\r\n彖曰：离丽也日月丽乎天百谷草木丽乎土重明以丽乎正乃化成天下柔丽乎中正故是以畜牝牛吉也\r\n\r\n初九：履错然敬之无咎\r\n象曰：履错之敬以辟咎也\r\n\r\n六二：黄离元吉\r\n象曰：黄离元吉得中道也\r\n\r\n九三：日昃之离不鼓缶而歌则大耋之嗟凶\r\n象曰：日昃之离何可久也\r\n\r\n九四：突如其来如焚如死如弃如\r\n象曰：突如其来如无所容也\r\n\r\n六五：出涕沱若戚嗟若吉\r\n象曰：六五之吉离王公也\r\n\r\n上九：王用出征有嘉折首获匪其丑无咎\r\n象曰：王用出征以正邦也\r\n",
        0b101110: "革：已日乃孚元亨利贞悔亡\r\n象曰：泽中有火革君子以治历明时\r\n彖曰：革水火相息二女同居其志不相得曰革已日乃孚革而信之文明以说大亨以正革而当其悔乃亡天地革而四时成汤武革命顺乎天而应乎人革之时大矣哉\r\n\r\n初九：巩用黄牛之革\r\n象曰：巩用黄牛不可以有为也\r\n\r\n六二：巳日乃革之征吉无咎\r\n象曰：巳日革之行有嘉也\r\n\r\n九三：征凶贞厉革言三就有孚\r\n象曰：革言三就又何之矣\r\n\r\n九四：悔亡有孚改命吉\r\n象曰：改命之吉信志也\r\n\r\n九五：大人虎变未占有孚\r\n象曰：大人虎变其文炳也\r\n\r\n上六：君子豹变小人革面征凶居贞吉\r\n象曰：君子豹变其文蔚也小人革面顺以从君也\r\n",
        0b101111: "同人：同人于野亨利涉大川利君子贞\r\n象曰：天与火同人君子以类族辨物\r\n彖曰：同人柔得位得中而应乎乾曰同人同人曰同人于野亨利涉大川乾行也文明以健中正而应君子正也唯君子为能通天下之志\r\n\r\n初九：同人于门无咎\r\n象曰：出门同人又谁咎也\r\n\r\n六二：同人于宗吝\r\n象曰：同人于宗吝道也\r\n\r\n九三：伏戎于莽升其高陵三岁不兴\r\n象曰：伏戎于莽敌刚也三岁不兴安行也\r\n\r\n九四：乘其墉弗克攻吉主\r\n象曰：乘其墉义弗克也其吉则困而反则也\r\n\r\n九五：同人先号咷而后笑大师克相遇\r\n象曰：同人之先以中直也大师相遇言相克也\r\n\r\n上九：同人于郊无悔\r\n象曰：同人于郊志未得也\r\n",
        0b110000: "临：元亨利贞至于八月有凶\r\n象曰：泽上有地临君子以教思无穷容保民无疆\r\n彖曰：临刚浸而长说而顺刚中而应大亨以正天之道也至于八月有凶消不久也\r\n\r\n初九：咸临贞吉\r\n象曰：咸临贞吉志行正也\r\n\r\n九二：咸临吉无不利\r\n象曰：咸临吉无不利未顺命也\r\n\r\n六三：甘临无攸利既忧之无咎\r\n象曰：甘临位不当也既忧之咎不长也\r\n\r\n六四：至临无咎\r\n象曰：至临无咎位当也\r\n\r\n六五：知临大君之宜吉\r\n象曰：大君之宜行中之谓也\r\n\r\n上六：敦临吉无咎\r\n象曰：敦临之吉志在内也\r\n",
        0b110001: "损：有孚元吉无咎可贞利有攸往曷之用二簋可用享\r\n象曰：山下有泽损君子以惩忿窒欲\r\n彖曰：损损下益上其道上行损而有孚元吉无咎可贞利有攸往曷之用二簋可用享二簋应有时损刚益柔有时损益盈虚与时偕行\r\n\r\n初九：已事遄往无咎酌损之\r\n象曰：已事遄往尚合志也\r\n\r\n九二：利贞征凶弗损益之\r\n象曰：九二利贞中以为志也\r\n\r\n六三：三人行则损一人一人行则得其友\r\n象曰：一人行三则疑也\r\n\r\n六四：损其疾使遄有喜无咎\r\n象曰：损其疾亦可喜也\r\n\r\n六五：或益之十朋之龟弗克违元吉\r\n象曰：六五元吉自上祐也\r\n\r\n上九：弗损益之无咎贞吉利有攸往得臣无家\r\n象曰：弗损益之大得志也\r\n",
        0b110010: "节：亨苦节不可贞\r\n象曰：泽上有水节君子以制数度议德行\r\n彖曰：节亨刚柔分而刚得中苦节不可贞其道穷也说以行险当位以节中正以通天地节而四时成节以制度不伤财不害民\r\n\r\n初九：不出户庭无咎\r\n象曰：不出户庭知通塞也\r\n\r\n九二：不出门庭凶\r\n象曰：不出门庭凶失时极也\r\n\r\n六三：不节若则嗟若无咎\r\n象曰：不节之嗟又谁咎也\r\n\r\n六四：安节亨\r\n象曰：安节之亨承上道也\r\n\r\n九五：甘节吉往有尚\r\n象曰：甘节之吉居位中也\r\n\r\n上六：苦节贞凶悔亡\r\n象曰：苦节贞凶其道穷也\r\n",
        0b110011: "中孚：豚鱼吉利涉大川利贞\r\n象曰：泽上有风中孚君子以议狱缓死\r\n彖曰：中孚柔在内而刚得中说而巽孚乃化邦也豚鱼吉信及豚鱼也利涉大川乘木舟虚也中孚以利贞乃应乎天也\r\n\r\n初九：虞吉有它不燕\r\n象曰：初九虞吉志未变也\r\n\r\n九二：鸣鹤在阴其子和之我有好爵吾与尔靡之\r\n象曰：其子和之中心愿也\r\n\r\n六三：得敌或鼓或罢或泣或歌\r\n象曰：或鼓或罢位不当也\r\n\r\n六四：月几望马匹亡无咎\r\n象曰：马匹亡绝类上也\r\n\r\n九五：有孚挛如无咎\r\n象曰：有孚挛如位正当也\r\n\r\n上九：翰音登于天贞凶\r\n象曰：翰音登于天何可长也\r\n",
        0b110100: "归妹：征凶无攸利\r\n象曰：泽上有雷归妹君子以永终知敝\r\n彖曰：归妹天地之大义也天地不交而万物不兴归妹人之终始也说以动所归妹也征凶位不当也无攸利柔乘刚也\r\n\r\n初九：归妹以娣跛能履征吉\r\n象曰：归妹以娣以恒也跛能履吉相承也\r\n\r\n九二：眇能视利幽人之贞\r\n象曰：利幽人之贞未变常也\r\n\r\n六三：归妹以须反归以娣\r\n象曰：归妹以须未当也\r\n\r\n九四：归妹愆期迟归有时\r\n象曰：愆期之志有待而行也\r\n\r\n六五：帝乙归妹其君之袂不如其娣之袂良月几望吉\r\n象曰：帝乙归妹不如其娣之袂良也其位在中以贵行也\r\n\r\n上六：女承筐无实士刲羊无血无攸利\r\n象曰：上六无实承虚筐也\r\n",
        0b110101: "睽：小事吉\r\n象曰：上火下泽睽君子以同而异\r\n彖曰：睽火动而上泽动而下二女同居其志不同行说而丽乎明柔进而上行得中而应乎刚是以小事吉天地睽而其事同也男女睽而其志通也万物睽而其事类也睽之时用大矣哉\r\n\r\n初九：悔亡丧马勿逐自复见恶人无咎\r\n象曰：见恶人以辟咎也\r\n\r\n九二：遇主于巷无咎\r\n象曰：遇主于巷未失道也\r\n\r\n六三：见舆曳其牛掣其人天且劓无初有终\r\n象曰：见舆曳位不当也无初有终遇刚也\r\n\r\n九四：睽孤遇元夫交孚厉无咎\r\n象曰：交孚无咎志行也\r\n\r\n六五：悔亡厥宗噬肤往何咎\r\n象曰：厥宗噬肤往有庆也\r\n\r\n上九：睽孤见豕负涂载鬼一车先张之弧后说之弧匪寇婚媾往遇雨则吉\r\n象曰：遇雨之吉群疑亡也\r\n",
        0b110110: "兑：亨利贞\r\n象曰：丽泽兑君子以朋友讲习\r\n彖曰：兑说也刚中而柔外说以利贞是以顺乎天而应乎人说以先民民忘其劳说以犯难民忘其死说之大民劝矣哉\r\n\r\n初九：和兑吉\r\n象曰：和兑之吉行未疑也\r\n\r\n九二：孚兑吉悔亡\r\n象曰：孚兑之吉信志也\r\n\r\n六三：来兑凶\r\n象曰：来兑之凶位不当也\r\n\r\n九四：商兑未宁介疾有喜\r\n象曰：九四之喜有庆也\r\n\r\n九五：孚于剥有厉\r\n象曰：孚于剥位正当也\r\n\r\n上六：引兑\r\n象曰：上六引兑未光也\r\n",
        0b110111: "履：履虎尾不咥人亨\r\n象曰：上天下泽履君子以辨上下定民志\r\n彖曰：履柔履刚也说而应乎乾是以履虎尾不咥人亨刚中正履帝位而不疚光明也\r\n\r\n初九：素履往无咎\r\n象曰：素履之往独行愿也\r\n\r\n九二：履道坦坦幽人贞吉\r\n象曰：幽人贞吉中不自乱也\r\n\r\n六三：眇能视跛能履履虎尾咥人凶武人为于大君\r\n象曰：眇能视不足以有明也跛能履不足以与行也咥人之凶位不当也武人为于大君志刚也\r\n\r\n九四：履虎尾愬愬终吉\r\n象曰：愬愬终吉志行也\r\n\r\n九五：夬履贞厉\r\n象曰：夬履贞厉位正当也\r\n\r\n上九：视履考祥其旋元吉\r\n象曰：元吉在上大有庆也\r\n",
        0b111000: "泰：小往大来吉亨\r\n象曰：天地交泰后以财成天地之道辅相天地之宜以左右民\r\n彖曰：泰小往大来吉亨则是天地交而万物通也上下交而其志同也内阳而外阴内健而外顺内君子而外小人君子道长小人道消也\r\n\r\n初九：拔茅茹以其汇征吉\r\n象曰：拔茅征吉志在外也\r\n\r\n九二：包荒用冯河不遐遗朋亡得尚于中行\r\n象曰：包荒得尚于中行以光大也\r\n\r\n九三：无平不陂无往不复艰贞无咎勿恤其孚于食有福\r\n象曰：无往不复天地际也\r\n\r\n六四：翩翩不富以其邻不戒以孚\r\n象曰：翩翩不富皆失实也不戒以孚中心愿也\r\n\r\n六五：帝乙归妹以祉元吉\r\n象曰：以祉元吉中以行愿也\r\n\r\n上六：城复于隍勿用师自邑告命贞吝\r\n象曰：城复于隍其命乱也\r\n",
        0b111001: "大畜：利贞不家食吉利涉大川\r\n象曰：天在山中大畜君子以多识前贤往行以畜其德\r\n彖曰：大畜刚健笃实辉光日新其德刚上而尚贤能止健大正也不家食吉养贤也利涉大川应乎天也\r\n\r\n初九：有厉利已\r\n象曰：有厉利已不犯灾也\r\n\r\n九二：舆说輹\r\n象曰：舆说輹中无尤也\r\n\r\n九三：良马逐利艰贞曰闲舆卫利有攸往\r\n象曰：利有攸往上合志也\r\n\r\n六四：童牛之牿元吉\r\n象曰：六四元吉有喜也\r\n\r\n六五：豮豕之牙吉\r\n象曰：六五之吉有庆也\r\n\r\n上九：何天之衢亨\r\n象曰：何天之衢道大行也\r\n",
        0b111010: "需：有孚光亨贞吉利涉大川\r\n象曰：云上于天需君子以饮食宴乐\r\n彖曰：需须也险在前也刚健而不陷其义不困穷矣需有孚光亨贞吉位乎天位以正中也利涉大川往有功也\r\n\r\n初九：需于郊利用恒无咎\r\n象曰：需于郊不犯难行也利用恒无咎未失常也\r\n\r\n九二：需于沙小有言终吉\r\n象曰：需于沙衍在中也虽小有言以终吉也\r\n\r\n九三：需于泥致寇至\r\n象曰：需于泥灾在外也自我致寇敬慎不败也\r\n\r\n六四：需于血出自穴\r\n象曰：需于血顺以听也\r\n\r\n九五：需于酒食贞吉\r\n象曰：酒食贞吉以中正也\r\n\r\n上六：入于穴有不速之客三人来敬之终吉\r\n象曰：不速之客来敬之终吉虽不当位未大失也\r\n",
        0b111011: "小畜：亨密云不雨自我西郊\r\n象曰：风行天上小畜君子以懿文德\r\n彖曰：小畜柔得位而上下应之曰小畜健而巽刚中而志行乃亨密云不雨尚往也自我西郊施未行也\r\n\r\n初九：复自道何其咎吉\r\n象曰：复自道其义吉也\r\n\r\n九二：牵复吉\r\n象曰：牵复在中亦不自失也\r\n\r\n九三：舆说辐夫妻反目\r\n象曰：夫妻反目不能正室也\r\n\r\n六四：有孚血去惕出无咎\r\n象曰：有孚惕出上合志也\r\n\r\n九五：有孚挛如富以其邻\r\n象曰：有孚挛如不独富也\r\n\r\n上九：既雨既处尚德载妇贞厉月几望君子征凶\r\n象曰：既雨既处德积载也君子征凶有所疑也\r\n",
        0b111100: "大壮：利贞\r\n象曰：雷在天上大壮君子以非礼弗履\r\n彖曰：大壮大者壮也刚以动故壮大壮利贞大者正也正大而天地之情可见矣\r\n\r\n初九：壮于趾征凶有孚\r\n象曰：壮于趾其孚穷也\r\n\r\n九二：贞吉\r\n象曰：九二贞吉以中也\r\n\r\n九三：小人用壮君子用罔贞厉羝羊触藩羸其角\r\n象曰：小人用壮君子以罔也\r\n\r\n九四：贞吉悔亡藩决不羸壮于大舆之輹\r\n象曰：藩决不羸尚往也\r\n\r\n六五：丧羊于易无悔\r\n象曰：丧羊于易位不当也\r\n\r\n上六：羝羊触藩不能退不能遂无攸利艰则吉\r\n象曰：不能退不能遂不详也艰则吉咎不长也\r\n",
        0b111101: "大有：元亨\r\n象曰：火在天上大有君子以遏恶扬善顺天休命\r\n彖曰：大有柔得尊位大中而上下应之曰大有其德刚健而文明应乎天而时行是以元亨\r\n\r\n初九：无交害匪咎艰则无咎\r\n象曰：大有初九无交害也\r\n\r\n九二：大车以载有攸往无咎\r\n象曰：大车以载积中不败也\r\n\r\n九三：公用亨于天子小人弗克\r\n象曰：公用亨于天子小人害也\r\n\r\n九四：匪其彭无咎\r\n象曰：匪其彭无咎明辨晰也\r\n\r\n六五：厥孚交如威如吉\r\n象曰：厥孚交如信以发志也威如之吉易而无备也\r\n\r\n上九：自天祐之吉无不利\r\n象曰：大有上吉自天祐也\r\n",
        0b111110: "夬：扬于王庭孚号有厉告自邑不利即戎利有攸往\r\n象曰：泽上于天夬君子以施禄及下居德则忌\r\n彖曰：夬决也刚决柔也健而说决而和扬于王庭柔乘五刚也孚号有厉其危乃光也告自邑不利即戎所尚乃穷也利有攸往刚长乃终也\r\n\r\n初九：壮于前趾往不胜为咎\r\n象曰：不胜而往咎也\r\n\r\n九二：惕号莫夜有戎勿恤\r\n象曰：有戎勿恤得中道也\r\n\r\n九三：壮于頄有凶君子夬夬独行遇雨若濡有愠无咎\r\n象曰：君子夬夬终无咎也\r\n\r\n九四：臀无肤其行次且牵羊悔亡闻言不信\r\n象曰：其行次且位不当也闻言不信聪不明也\r\n\r\n九五：苋陆夬夬中行无咎\r\n象曰：中行无咎中未光也\r\n\r\n上六：无号终有凶\r\n象曰：无号之凶终不可长也\r\n",
        0b111111: "乾：元亨利贞\r\n象曰：天行健君子以自强不息\r\n彖曰：大哉乾元万物资始乃统天云行雨施品物流形大明终始六位时成时乘六龙以御天乾道变化各正性命保合大和乃利贞首出庶物万国威宁\r\n\r\n初九：潜龙勿用\r\n象曰：潜龙勿用阳在下也\r\n\r\n九二：见龙在田利见大人\r\n象曰：见龙在田德施普也\r\n\r\n九三：君子终日乾乾夕惕若厉无咎\r\n象曰：终日乾乾反复道也\r\n\r\n九四：或跃在渊无咎\r\n象曰：或跃在渊进无咎也\r\n\r\n九五：飞龙在天利见大人\r\n象曰：飞龙在天大人造也\r\n\r\n上九：亢龙有悔\r\n象曰：亢龙有悔盈不可久也\r\n\r\n用九：见群龙无首吉\r\n象曰：用九天德不可为首也\r\n"           
    }
    public getText(): string {
        return YiHexagram.texts[this.hexagram];
    }
}
