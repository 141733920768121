import * as React from "react";
import './Clear.css';

export default class Clear extends React.Component {
    render() {
        return (
            <div className="Clear"></div>
        );
    }
}
